<template>
  <div class="main-wrapper" :style="`background-image:url(${this.bg})`">
    <!-- Page Banner Start -->
    <div class="section page-banner-section" :style="`background-image:url(${this.bg_ai})`">
      <div class="shape-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
          <defs>
            <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
              <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
              <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
            </linearGradient>

          </defs>
          <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
          <path fill="url(#PSgrad_0)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
        </svg>
      </div>
      <div class="shape-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
          <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
        </svg>
      </div>
      <div class="shape-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
          <defs>
            <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
              <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
              <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
            </linearGradient>

          </defs>
          <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
          <path fill="url(#PSgrad_0)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
        </svg>
      </div>
      <div class="shape-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
          <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
        </svg>
      </div>
      <div class="container">
        <div class="page-banner-wrap">
          <div class="row">
            <div class="col-lg-8">
              <!-- Page Banner Content Start -->
              <div class="page-banner text-left hero-content">
                <h2 class="title shadow-text " data-aos-delay="600" data-aos="fade-up">AI PRODUCTS & SERVICES</h2>
              </div>
              <!-- Page Banner Content End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Banner End -->


    <div class="section techwix-testimonial-section section-padding-02">
      <div class="container">
        <!-- Testimonial Wrap Start  -->
        <div class="zero-padding-lr content_block" data-aos="fade-right" data-aos-delay="500">
          <div class="row">
            <div class="col-lg-6">
              <div class="testimonial-img">
                <img src="../assets/img/ai1.png" alt="" style="max-width: 50%">
                  <img src="../assets/img/ai2.png" alt="" style="max-width: 50%">
                  <img src="../assets/img/ai3.png" alt="" style="max-width: 50%">
                  <img src="../assets/img/ai4.png" alt="" style="max-width: 50%">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="testimonial-content-wrap">
                <div>
                  <h2 class="text-blue" style="margin-bottom: 1em">AI COMPUTING POWER SERVERS</h2>
                  <h3 class="title">Ultra-high computing power:</h3>
                  <p class="font_sm text-left">Equipped with 8 GPUs, it provides a combined computing power of 5280 TFLOPs, with excellent parallel task processing capabilities, easily handling compute-intensive workloads and facilitating the implementation of various professional AI applications.</p>
                  <h3>Powerful performance:</h3>
                  <p class="font_sm text-left">Adopting dual-socket scalable series processors, it can meet the processing needs of different customer application scenarios and easily handle demanding applications.</p>
                  <h3>Server deployment scale assurance:</h3>
                  <p class="font_sm text-left">The company has established long-term strategic cooperation with GPU suppliers such as NVIDIA and AMD, ensuring the continuous supply of computing card boards.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="zero-padding content_block" data-aos="fade-left" data-aos-delay="500">
          <div class="row">
            <div class="col-lg-6">
              <div class="testimonial-content-wrap">
                <div>
                  <h2 class="text-blue" style="margin-bottom: 1em">AI COMPUTING POWER CLOUD SERVICES</h2>
                  <p class="font_sm text-left">The company is committed to creating a new model for AI computing power cloud services, achieving unified management of diverse computing power pools such as basic computing resource pools, GPU
                      resource pools, EHPC resource pools, and supercomputing resource pools. It also realizes the allocation of high-performance, high-efficiency, and low- cost computing and storage resources, completing tasks such as high-speed parallel computing and storage, distributed scheduling and management, multi-zone and multi-business resource integration, algorithm development support, hybrid networking, container inference services, model repositories, and AI training services.
                      Not only does this meet the varying needs of different clients for elastic computing power, but it also provides highly customized AI cloud computing power services. This allows clients to seamlessly, economically, and efficiently operate various artificial intelligence computing tasks across different cloud environments.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="testimonial-img">
                <img src="../assets/img/ai5.png" alt="" style="max-width: 100%">
              </div>
            </div>
          </div>
        </div>
        <div class="zero-padding content_block" data-aos="fade-right" data-aos-delay="500">
          <div class="row">
            <div class="col-lg-6">
              <div class="testimonial-img">
                <img src="../assets/img/ai6.png" alt="" style="max-width: 100%">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="testimonial-content-wrap">
                <div>
                  <h2 class="text-blue" style="margin-bottom: 1em">PRODUCTS AND TECHNICAL SERVICES</h2>
                  <h3>AI Computing Power Cloud Services</h3>
                  <p class="font_sm text-left">
                      We offer stable, highly customized AI computing power cloud services and AI computing power solutions.
                  </p>
                    <h3>Flexible leasing models – periodic or on-demand</h3>
                    <p class="font_sm text-left">
                        To meet the changing needs of enterprises, we provide a flexible leasing model.
                    </p>
                    <h3>Model secondary development</h3>
                    <p class="font_sm text-left">
                        For the leasing needs of government and enterprise users, we provide a secondary development environment based on large-scale models.
                    </p>
                    <h3>Technical support</h3>
                    <p class="font_sm text-left">
                        We offer comprehensive technical support for leasing services to meet the operational and development needs of enterprises.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="zero-padding content_block" data-aos="fade-left" data-aos-delay="500">
              <div class="row">
                  <div class="col-lg-6">
                      <div class="testimonial-content-wrap">
                          <div>
                              <h2 class="text-blue" style="margin-bottom: 1em">TECHNICAL ADVANTAGES</h2>
                              <h3>Cluster performance advantages:</h3>
                              <p class="font_sm text-left">
                                  The company deploys server clusters for massive parallel computing and storage. Its high-performance AI computing servers are deeply coupled with storage server clusters, enabling parallel computing and storage of large-scale datasets, and possess strong performance in AI training.
                              </p>
                              <h4>Comprehensive AI cloud computing power management platform:</h4>
                              <p class="font_sm text-left" style="line-height: 1.3em">
                                  Relying on the AI cloud computing power management platform system, it is possible to efficiently operate and maintain the massive server devices deployed in the data center. It not only achieves status monitoring, hardware management, remote control, node configuration, distributed file creation, hot data tracking, AI acceleration of the server clusters, but also the modular design and secondary development capabilities of the management platform, as well as the use of leading virtualization technology for the configuration of GPU, EHPC, supercomputing, storage, and other resource pools, can unify the management of various heterogeneous hardware devices and meet the needs for seamless expansion.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="testimonial-img">
                          <img src="../assets/img/ai7.png" alt="" style="max-width: 100%">
                      </div>
                  </div>
              </div>
          </div>
        <!-- Testimonial Wrap End  -->
      </div>
    </div>
  </div>
</template>

<script>

    export default {
        name: 'AI',
        data() {
            return {
                bg_ai: require('../assets/img/ai_bg1.png'),
                bg: require('../assets/img/bg_contact.png'),
            }
        },
        methods:{
           getBannerStyle(){
              return
           }
        },
        mounted() {
        }
    }
</script>

<style>
    .miner {
        margin: 0;
        position: fixed; bottom: 0; left: 0;
        height: 25em;
        z-index: -1;
    }

    div .desc {
        color: #fff;
        display: inline-block;
        width: 40em;
        float: right;
        text-align: left;
        margin-top: 5em;
    }

    .font_sm {
        font-size: 1.5em;
    }
    .font_md {
        font-size: 2.5em;
    }
    .font_xl {
        font-size: 6em;
    }
    .font_lg {
        font-size: 3.5em;
    }
    .font_bold {
        font-weight: bold;
    }

    .text-left {
        text-align: left;
        text-indent: 2em;
    }

    @media (max-width: 700px) {
        .miner {
            height: 25%;
            bottom: 1em
        }
        td {
            padding: 0 15px;
        }
        div .desc {
            margin: 2em 0 0 6em;
            width: 100%;
        }
        .font_sm {
            font-size: 1em;
        }
        .font_md {
            font-size: 1.5em;
        }
        .font_xl {
            font-size: 3em;
        }
        .font_lg {
            font-size: 1.75em;
        }
    }
    @media (min-width: 610px) and (max-width: 1200px) {
        .font_sm {
            font-size: 1em;
        }
        .font_md {
            font-size: 1.5em;
        }
        .font_xl {
            font-size: 3em;
        }
        .font_lg {
            font-size: 1.75em;
        }
        div .desc {
            width: 40em;
            margin-top: 5em;
        }
        .miner { height: 35%; }
    }

    @media (min-width: 1200px) and (min-height: 1200px) {
        .miner {
            height: 45em;
        }
        div .desc {
            margin-top: 5em;
            margin-right: 4em;
            width: 50em;
        }
    }
    @media (max-height: 600px) {
        div .desc {
            margin-top: 1em;
            float: right;
            max-width: 55%;
        }
    }
    @media (min-width: 1400px) {
        .container {
            max-width: 1400px;
        }
    }
    .desc div {
        overflow: hidden;
    }
</style>

<style scoped>
    .content_block{
        margin-bottom: 3em;
        background-color: rgba(0,0,0,0.05) !important;
        padding: 1em;
    }
    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }

    @media (max-width: 700px) {
        .miner {
            bottom: 0 !important;
        }
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    h3, h4 {
        position: relative;
        padding-left: 13px;
        margin: 10px 0;
        text-align: left;
    }
    h4 {
       font-size: 1.5em;
    }

    h3:before, h4:before {
        content: "";
        background-color: #ee3737;
        width: 4px;
        height: 0.8em;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
</style>

<style>

</style>
