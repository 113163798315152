import Vue from 'vue'
import App from './App.vue'
import router from './router'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
import {messages} from './assets/js/i18n'
import 'vue-awesome/icons/globe'
import Icon from 'vue-awesome/components/Icon'
import flags from 'flag-icon-css/css/flag-icons.css'

Vue.component('v-icon', Icon)
Vue.config.productionTip = false
Vue.use(elementUI)
Vue.use(VueI18n);
// let lang = localStorage.lang || (navigator.language.startsWith('zh')? 'cn': 'en')
let lang = 'en'
const i18n = new VueI18n({
    locale: lang,
    messages: messages
});

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')
