const messages = {
    en:{
    	home: 'Home',
		intro: 'Intro',
		product: 'Product',
		contact: 'Contact',
		introLeft1: 'Ten years passed quickly',
		introLeft2: 'has never forgotten the original intention.',
		introLeft3: 'Our team has accumulated rich experience in the design of ASIC chips for Bitcoin miners.',
		introLeft4: '100% tape-out success in multiple processes such as 55nm, 16nm, 10nm, 8nm, N+1, etc.',
		introLeft5: 'Never failed.',
		introRight1: 'Mass produced the first 55nm ASIC chip for Bitcoin miner at SMIC. Self-built and self-maintained Bitcoin mining farm.',
		introRight2: 'Deployed and maintained over 20,000 Bitcoin miners.',
		introRight3: 'Mass produced the world\'s first 16nm ASIC chip at TSMC. Built a Bitcoin mining farm in Houston, covering over 200 acres.',
		introRight4: 'Produced 10nm ASIC chips in Samsung Semiconductor and became their largest Chinese customer that year.',
		introRight5: 'Completed the second update of 10nm process at Samsung Semiconductor.',
		introRight6: 'Achieved a new technological breakthrough and began mass production of N+1 process at SMIC, with a power consumption lower than 30W/Th.',
		prod0: 'Overview',
		prod1: 'All new MinerVa MV7 Bitcoin miner, a new breakthrough in performance!',
		prod2: 'HashPower',
		prod3: 'Over 120T HashPower',
		prod4: 'Some even approached 130Th',
		prod5: 'Power Consumption',
		prod6: 'Less than 30J/Th',
		prod7: 'Some even approached 25J/Th',
		address: 'Address',
		email: 'Email',
		btc: 'BTC Miner',
		eth: 'Ether ASIC',
		ltc: 'LTC Miner',
		eth1: 'MinerVa E15, a high performance Ethash ASIC chip, supports multiple Ethash algorithms blockchain, including ETH, ETC, ETP, ETHW...',
		eth2: 'Over 20M Hashpower per chip',
		eth3: 'Miner Hashpower over 2000M',
		eth4: 'Less than 5J/Mh',
		eth5: 'Significantly lower than GPU mining',
		ltc1: 'MinerVa ML7 Litecoin & Dogecoin miner, powered by all new MinerVa L16 7nm Scrypt ASIC chip.',
		ltc2: 'Over 9000M HashPower',
		ltc3: 'Miner Hashpower over 2000M',
		ltc4: 'Overall Consumption 3100w',
		ltc5: 'Less than 0.35J/Mh',
		aiProd: 'AI Prod & Services'

    },
    cn:{
		home: '首页',
		intro: '公司简介',
		product: '产品介绍',
		contact: '联系我们',
		introLeft1: '光阴十载',
		introLeft2: '不忘初心。',
		introLeft3: '主力团队积累了丰富的比特币矿机ASIC芯片设计经验。',
		introLeft4: '1在55纳米、16n纳米、10纳米、8纳米、N+1等多个制程100%流片成功。',
		introLeft5: '未尝败绩。',
		introRight1: '在中芯国际量产了第一款55纳米制程比特币矿机ASIC芯片。自建自持比特币矿场。',
		introRight2: '完成超过两万台比特币矿机的部署与运维。',
		introRight3: '在台积电量产全球第一颗16纳米制程比特币矿机ASIC芯片。在美国休斯顿建设比特币矿场， 占地200亩。',
		introRight4: '在三星半导体量产10纳米制程比特币矿机ASIC芯片，并成为三星半导体当年最大的中国客户。',
		introRight5: '在三星半导体完成了10纳米制程比特币矿机ASIC芯片的第二次更新。',
		introRight6: '完成新一代技术突破，在中芯国际开始N+1制程的比特币矿机芯片量产，功耗低于30W/Th。',
		prod0: '产品性能',
		prod1: 'MinerVa新品MV7矿机，在性能上实现新的突破！',
		prod2: '整机算力',
		prod3: '整机算力超过120Th',
		prod4: '部分整机算力近130Th。',
		prod5: '单位算力功耗',
		prod6: '单Th功耗小于30w',
		prod7: '部分机器单Th功耗接近25w。',
		address: '地址',
		email: '邮箱',
		btc: '比特币矿机',
		eth: '以太坊芯片',
		ltc: '莱特币矿机',
		aiProd: 'AI产品与解决方案'
    }
}

export {messages}
