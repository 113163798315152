<template>
    <div class="home">
        <el-row>
            <el-col style="text-align: left; " :xl="24">
                <img src="../assets/img/ltc.png" class="miner">
                <div class="desc">
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[0]">
                                <tr>
                                    <td style="opacity: 80%" class="font_sm">Product</td>
                                    <td rowspan="3" class="font_xl font_bold">ML7</td>
                                </tr>
                                <tr>
                                    <td class="font_md font_bold">{{$t('prod0')}}</td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('ltc1')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[1]">
                                <tr>
                                    <td class="font_md font_bold">Performance</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('prod2')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="width: 3.75em" class="font_lg">>9000M</td>
                                    <td class="font_sm">{{$t('ltc2')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('ltc3')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[2]">
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('prod5')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="width: 4.5em; padding-right: 0.5em" class="font_lg"><0.35J/Mh</td>
                                    <td class="font_sm">{{$t('ltc4')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('ltc5')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        name: 'LTC',
        data() {
            return {
                showTable: []
            }
        },
        mounted() {
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    this.showTable.push(true)
                }, 888 * (i + 1))
            }
        }
    }
</script>

<style scoped>
    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }
    .miner {
        left: 2em;
        bottom: 1em;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style>

</style>
